@media only screen and (min-width: 0px) {
  .faq {
    padding: 24px;
    text-align: center;
  }
}

@media only screen and (min-width: 500px) {
  .faq {
    padding: 32px;
  }
}

@media only screen and (min-width: 800px) {
  .faq {
    padding: 96px;
  }
}
