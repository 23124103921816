@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
.layout_layout__2Digl {
  background: url(/static/media/pattern.52cb54f3.jpg) repeat;
  height: 100%;
  width: 100%;
}

@media only screen and (min-width: 0px) {
  .header_header__3f8N6 {
    display: block;
    margin-top: 1em;
    text-align: center;
  }

  .header_headerColumn__ItnSl {
    width: 100%;
  }

  .header_headerRight__2A22h {
    width: 100%;
    margin-top: 8px;
  }

  .header_headerRight__2A22h > p {
    margin: 1.5em 0;
  }
}

@media only screen and (min-width: 700px) {
  .header_header__3f8N6 {
    display: flex;
    align-items: center;
    padding: 1em 3em;
  }

  .header_headerColumn__ItnSl {
    text-align: left;
  }

  .header_headerRight__2A22h {
    text-align: right;
  }
}

.header_partnershipLogo__2IpRq {
  max-height: 6em;
  max-width: 14.8125em;
}

@media only screen and (min-width: 0px) {
  .basket_basket__1p_KO {
    overflow-y: auto;
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
    background-color: #031454;
    box-shadow: -2px 0 8px 0 rgba(0, 0, 0, 0.25);
    color: #fff;
  }

  .basket_totals__3YQfX {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 1.5em;
    font-weight: normal;
  }
}

@media only screen and (min-width: 600px) {
  .basket_totals__3YQfX {
    font-size: 2em;
  }
}

@media only screen and (min-width: 1250px) {
  .basket_basket__1p_KO {
    position: fixed;
    top: 0;
    right: 0;
    width: 40%;
    padding: 48px;
  }
}

.basket_basketOpen__3YOdb {
  width: 100%;
}

.basket_basketOpenFake__362B9 {
  width: 100%;
  text-align: center;
}

.basket_title__P51KD {
  color: #a0804d;
}

.basket_title__P51KD {
  margin-bottom: 32px;
}

.basket_basketTitle__2ryZ5 {
  padding-bottom: 0px;
  margin-top: 8px;
}

.basket_basketItems__V_Gs3 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.basket_basketItem__2ceyd {
  display: flex;
  margin-bottom: 2em;
}

.basket_itemIcon__3k3iX {
  align-self: center;
  padding: 0.875em;
  margin-right: 2em;
  background-color: #29397a;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.basket_itemContent__3PR_n {
  flex: 2 1;
}

.basket_itemTitle__ey3xU {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1em;
  margin-bottom: 0.5em;
}

.basket_itemDesc__1QX-z {
  color: rgba(255, 255, 255, 0.5);
}

.basket_basketTotals__253o8 {
  list-style-type: none;
  margin: 0 0 2em 0;
  padding: 2em 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.basket_subTotals__16iQw {
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
  margin-bottom: 1em;
}

.basket_subText__1Q4hW {
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
  font-size: 1.1em;
  margin-top: 1em;
  line-height: 1.3em;
}

.wrap_wrap__1kCsp {
  max-width: 640px;
  margin: 0 auto;
}

.button_button__mr9Nr {
  display: inline-block;
  width: 15rem;
  margin-top: 2rem;
  padding: 0.75em;
  cursor: pointer;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1em;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
  border: none;
  outline: none;
  background-color: #031454;
}

.button_button__mr9Nr:hover,
.button_button__mr9Nr:active {
  background-color: #a0804d;
}

.button_buttonBlock__ybS6X {
  display: block;
  width: 100%;
}

.button_gold__1KPD_ {
  background-color: #a0804d;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
}

.button_lightNavy__1HulH,
.button_lightNavy__1HulH:hover,
.button_gold__1KPD_:active {
  background-color: #29397a;
}

.checkout-complete_checkoutComplete__4gPTF {
  text-align: center;
}

.checkout-complete_header__lFt6a {
  text-align: center;
  padding-bottom: 4em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 4em;
}

.checkout-complete_title__1Mns7 {
  color: #a0804d;
}

.checkout-complete_para__KtfRM {
  color: #fff;
  margin-bottom: 64px;
}

.form-elements_formGroup__14yUf {
  border-radius: 4px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}

.form-elements_element__W2Ivm {
  display: flex;
  padding: 0 0.75em;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  border-color: rgba(255, 255, 255, 0.1);
  background-color: #29397a;
}

.form-elements_checkboxElement__VycCC {
  display: flex;
  align-items: center;
  margin: 1em 0 0 0.75em;
}

.form-elements_formGroup__14yUf .form-elements_element__W2Ivm:first-of-type {
  border-radius: 4px 4px 0 0;
}

.form-elements_formGroup__14yUf .form-elements_element__W2Ivm:last-of-type {
  border-bottom-width: 1px;
  border-radius: 0 0 4px 4px;
}

@media only screen and (min-width: 0px) {
  .form-elements_label__3ZgL1,
  .form-elements_input__1R4fl,
  .form-elements_cardLabel__2atX5 {
    display: block;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 0.9em;
    padding-top: 0.1em;
    line-height: 2.5em;
    color: #fff;
    width: auto;
  }

  .form-elements_label__3ZgL1 {
    font-size: 0.9em;
    padding-top: 0.1em;
    min-width: 9em;
  }

  .form-elements_checkboxLabel__Vd5lQ {
    padding-left: 1em;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-size: 1em;
    line-height: 1.3em;
  }

  .form-elements_cardLabel__2atX5 {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .form-elements_label__3ZgL1,
  .form-elements_input__1R4fl,
  .form-elements_cardLabel__2atX5 {
    font-size: 1em;
    padding-top: 0;
  }

  .form-elements_checkboxLabel__Vd5lQ {
    line-height: 2.5em;
  }

  .form-elements_cardLabel__2atX5 {
    display: block;
    min-width: 9em;
  }
}

.form-elements_input__1R4fl {
  width: 100%;
  border: 0;
  outline: none;
  background: none;
}

.form-elements_input__1R4fl::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.form-elements_input__1R4fl:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.form-elements_input__1R4fl::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.form-elements_input__1R4fl::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.form-elements_input__1R4fl:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.form-elements_eulaLink__1-3F5 {
  color: #a0804d;
  cursor: pointer;
  text-decoration: none;
}

@media only screen and (min-width: 0px) {
  .checkout_header__3irHf,
  .checkout_backButtonContainer__gqOkV {
    display: none;
  }
}

@media only screen and (min-width: 1250px) {
  .checkout_header__3irHf {
    display: block;
    text-align: center;
    padding-bottom: 3em;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 2em;
  }

  .checkout_backButtonContainer__gqOkV {
    display: block;
    margin-bottom: 2em;
  }
}

option {
  background-color: #29397a;
}

.checkout_loader__IWCwN {
  border: 5px solid rgb(3, 20, 84);
  border-top: 5px solid #fff;
  border-radius: 50%;
  margin: auto;
  margin-top: 32px;
  width: 31px;
  height: 31px;
  -webkit-animation: checkout_spin__12Kqd 1s linear infinite;
          animation: checkout_spin__12Kqd 1s linear infinite;
}

@-webkit-keyframes checkout_spin__12Kqd {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkout_spin__12Kqd {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.checkout_subText__LmI_1 {
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
  font-size: 1.1em;
  margin-top: 1em;
  line-height: 1.3em;
}

.checkout_backButton__2wJh7 {
  height: 2.5em;
  cursor: pointer;
}

.eula-modal_eulaContainer__2ddqd {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  color: black;
}

.eula-modal_eulaModal__3Mpr- {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #faf8f6;
  top: 10%;
  left: 20%;
  width: 60%;
  height: 80%;
}

.eula-modal_eulaHeading__wk7mq {
  position: relative;
  width: 80%;
  padding-top: 2rem;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  font-weight: 700;
}

.eula-modal_eulaText__3Vevm {
  position: relative;
  height: 70%;
  width: 80%;
  overflow-y: scroll;
  white-space: pre-wrap;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  padding: 1rem 2rem;
  box-sizing: border-box;
  text-align: justify;
}

.eula-modal_eulaClose__2gL9g {
  margin-bottom: 3rem;
}

.eula-modal_eulaContainer__2ddqd ::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

.eula-modal_eulaContainer__2ddqd ::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin: 1rem 0;
  border-radius: 5px;
}

.eula-modal_eulaContainer__2ddqd ::-webkit-scrollbar-thumb {
  background: #a0804d;
  border-radius: 5px;
}

.eula-modal_eulaContainer__2ddqd ::-webkit-scrollbar-thumb:hover {
  background: #a0804d;
}

.error-404_error404Container__7ws-f {
  display: flex;
  align-items: center;
  padding: 2em 2em 0;
}

@media only screen and (min-width: 0px) {
  .hero_hero__3GujM {
    padding: 24px;
    text-align: center;
  }

  .hero_bullets__3yc8f {
    text-align: left;
    margin-left: 5px;
    margin-bottom: 10px;
    
  }
}

@media only screen and (min-width: 500px) {
  .hero_hero__3GujM {
    padding: 32px;
    text-align: center;
  }

  .hero_bullets__3yc8f {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 800px) {
  .hero_hero__3GujM {
    padding: 96px;
  }
}

#hero_heropara__2ix2f {
  text-align: left;
}

.hero_resourceContainer__1Jidd {
  margin-top: 1em;
}

.hero_resource__12wgj {
  padding-top: 2.5em;
  align-items: center;
}

.hero_resourceContent__14o2p {
  font-size: 20px;
  line-height: 35px;
  font-weight: 400;
  margin-bottom: 1em;
  width: 100%;
}

.hero_ctaExampleReport__3EHIj {
  margin-top: -2.5em;
  padding-bottom: 2.5em;
}

@media only screen and (min-width: 0px) {
  .example-report_exampleReport__2jpre {
    padding: 24px;
    background-color: #f9f9f9;
    text-align: center;
  }
}

@media only screen and (min-width: 500px) {
  .example-report_exampleReport__2jpre {
    padding: 32px;
  }
}

@media only screen and (min-width: 800px) {
  .example-report_exampleReport__2jpre {
    padding: 96px;
  }
}

.example-report_graphic__1gh-N {
  margin-top: 32px;
}

.example-report_container__3r14M {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.example-report_arrowRight__IW8Sn,
.example-report_arrowLeft__13CkI {
  position: relative;
  width: 40%;
}

.example-report_arrowImg__FNdRC {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.example-report_arrowRight__IW8Sn .example-report_arrowImg__FNdRC {
  transform: rotate(180deg);
}

.example-report_arrowRight__IW8Sn {
  transform: 0 0 0 1px;
}

.example-report_scoreContent__15fND {
  list-style-type: none;
  margin: 24px 0 0 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 0.875em;
  font-weight: 500;
  text-align: center;
}

.example-report_scoreContent__15fND li {
  display: block;
  max-width: 176px;
  margin: 0 auto 24px;
}

.example-report_scoreContent__15fND li:nth-of-type(2n + 1) {
  color: #a0804d;
}

.example-report_meter__G1Fic {
  position: relative;
  background-color: #fff;
  padding: 5px 5px 10px;
  border-radius: 99999em;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.25);
}

.example-report_meter__G1Fic:after {
  content: "\00a0";
  display: block;
  position: absolute;
  top: 120%;
  left: 50%;
  width: 0;
  padding: 0;
  margin: 0;
  line-height: 0;
  height: 7em;
  border-left: 0.3rem dotted #031454;
}

.example-report_ipad__2N-Sb {
  margin-top: 28px;
  width: 100%;
}

.link_link__2ZKn8,
.link_link__2ZKn8:visited {
  color: inherit;
}

.link_link__2ZKn8:active,
.link_link__2ZKn8:hover {
  color: #a0804d;
}

@media only screen and (min-width: 0px) {
  .faq_faq__GhDfu {
    padding: 24px;
    text-align: center;
  }
}

@media only screen and (min-width: 500px) {
  .faq_faq__GhDfu {
    padding: 32px;
  }
}

@media only screen and (min-width: 800px) {
  .faq_faq__GhDfu {
    padding: 96px;
  }
}

@media only screen and (min-width: 0px) {
  .crr-report_landingPageContent__5SCF7 {
    width: 100%;
  }
}

@media only screen and (min-width: 1250px) {
  .crr-report_landingPageContent__5SCF7 {
    width: 60%;
  }
}

.cancel-subscription_cancelSubscriptionPage__8tAzy {
  height: 100vh;
  width: 100vw;
}

.cancel-subscription_cancelSubscriptionContentBox__2MW1p {
  display: block;
  text-align: center;
  margin-top: 5em;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 350px;
  background: white;
  border: 1px solid #29397a;
  padding: 1.5em;
  box-sizing: border-box;
}

.cancel-subscription_loader__3r57G {
  border: 5px solid rgb(3, 20, 84);
  border-top: 5px solid #fff;
  border-radius: 50%;
  margin: auto;
  margin-top: 32px;
  width: 31px;
  height: 31px;
  -webkit-animation: cancel-subscription_spin__1qrSs 1s linear infinite;
          animation: cancel-subscription_spin__1qrSs 1s linear infinite;
}

@-webkit-keyframes cancel-subscription_spin__1qrSs {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes cancel-subscription_spin__1qrSs {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
/**
 * Add the correct display in IE.
 */
main {
  display: block;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}
/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE.
 */
template {
  display: none;
}
/* User interaction
 * ========================================================================== */

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #031454;
  font-size: 100%;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 2em;
  padding-bottom: 2em;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.375em;
  margin-bottom: 1.5em;
}

h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1em;
}

p {
  margin-bottom: 0.5em;
}

