@media only screen and (min-width: 0px) {
  .basket {
    overflow-y: auto;
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
    background-color: #031454;
    box-shadow: -2px 0 8px 0 rgba(0, 0, 0, 0.25);
    color: #fff;
  }

  .totals {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 1.5em;
    font-weight: normal;
  }
}

@media only screen and (min-width: 600px) {
  .totals {
    font-size: 2em;
  }
}

@media only screen and (min-width: 1250px) {
  .basket {
    position: fixed;
    top: 0;
    right: 0;
    width: 40%;
    padding: 48px;
  }
}

.basketOpen {
  composes: basket;
  width: 100%;
}

.basketOpenFake {
  composes: basket;
  width: 100%;
  text-align: center;
}

.title {
  color: #a0804d;
}

.title {
  margin-bottom: 32px;
}

.basketTitle {
  padding-bottom: 0px;
  margin-top: 8px;
}

.basketItems {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.basketItem {
  display: flex;
  margin-bottom: 2em;
}

.itemIcon {
  align-self: center;
  padding: 0.875em;
  margin-right: 2em;
  background-color: #29397a;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.itemContent {
  flex: 2;
}

.itemTitle {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1em;
  margin-bottom: 0.5em;
}

.itemDesc {
  color: rgba(255, 255, 255, 0.5);
}

.basketTotals {
  list-style-type: none;
  margin: 0 0 2em 0;
  padding: 2em 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.subTotals {
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
  margin-bottom: 1em;
}

.subText {
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
  font-size: 1.1em;
  margin-top: 1em;
  line-height: 1.3em;
}
