.checkoutComplete {
  text-align: center;
}

.header {
  text-align: center;
  padding-bottom: 4em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 4em;
}

.title {
  color: #a0804d;
}

.para {
  color: #fff;
  margin-bottom: 64px;
}
