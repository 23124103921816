@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import-normalize;

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #031454;
  font-size: 100%;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 2em;
  padding-bottom: 2em;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.375em;
  margin-bottom: 1.5em;
}

h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1em;
}

p {
  margin-bottom: 0.5em;
}
