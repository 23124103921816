.eulaContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  color: black;
}

.eulaModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #faf8f6;
  top: 10%;
  left: 20%;
  width: 60%;
  height: 80%;
}

.eulaHeading {
  position: relative;
  width: 80%;
  padding-top: 2rem;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  font-weight: 700;
}

.eulaText {
  position: relative;
  height: 70%;
  width: 80%;
  overflow-y: scroll;
  white-space: pre-wrap;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  padding: 1rem 2rem;
  box-sizing: border-box;
  text-align: justify;
}

.eulaClose {
  margin-bottom: 3rem;
}

.eulaContainer ::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

.eulaContainer ::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin: 1rem 0;
  border-radius: 5px;
}

.eulaContainer ::-webkit-scrollbar-thumb {
  background: #a0804d;
  border-radius: 5px;
}

.eulaContainer ::-webkit-scrollbar-thumb:hover {
  background: #a0804d;
}
