@media only screen and (min-width: 0px) {
  .exampleReport {
    padding: 24px;
    background-color: #f9f9f9;
    text-align: center;
  }
}

@media only screen and (min-width: 500px) {
  .exampleReport {
    padding: 32px;
  }
}

@media only screen and (min-width: 800px) {
  .exampleReport {
    padding: 96px;
  }
}

.graphic {
  margin-top: 32px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrowRight,
.arrowLeft {
  position: relative;
  width: 40%;
}

.arrowImg {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.arrowRight .arrowImg {
  transform: rotate(180deg);
}

.arrowRight {
  transform: 0 0 0 1px;
}

.scoreContent {
  list-style-type: none;
  margin: 24px 0 0 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 0.875em;
  font-weight: 500;
  text-align: center;
}

.scoreContent li {
  display: block;
  max-width: 176px;
  margin: 0 auto 24px;
}

.scoreContent li:nth-of-type(2n + 1) {
  color: #a0804d;
}

.meter {
  position: relative;
  background-color: #fff;
  padding: 5px 5px 10px;
  border-radius: 99999em;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.25);
}

.meter:after {
  content: "\00a0";
  display: block;
  position: absolute;
  top: 120%;
  left: 50%;
  width: 0;
  padding: 0;
  margin: 0;
  line-height: 0;
  height: 7em;
  border-left: 0.3rem dotted #031454;
}

.ipad {
  margin-top: 28px;
  width: 100%;
}
