@media only screen and (min-width: 0px) {
  .header,
  .backButtonContainer {
    display: none;
  }
}

@media only screen and (min-width: 1250px) {
  .header {
    display: block;
    text-align: center;
    padding-bottom: 3em;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 2em;
  }

  .backButtonContainer {
    display: block;
    margin-bottom: 2em;
  }
}

option {
  background-color: #29397a;
}

.loader {
  border: 5px solid rgb(3, 20, 84);
  border-top: 5px solid #fff;
  border-radius: 50%;
  margin: auto;
  margin-top: 32px;
  width: 31px;
  height: 31px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.subText {
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.5);
  font-weight: normal;
  font-size: 1.1em;
  margin-top: 1em;
  line-height: 1.3em;
}

.backButton {
  height: 2.5em;
  cursor: pointer;
}
