@media only screen and (min-width: 0px) {
  .header {
    display: block;
    margin-top: 1em;
    text-align: center;
  }

  .headerColumn {
    width: 100%;
  }

  .headerRight {
    width: 100%;
    margin-top: 8px;
  }

  .headerRight > p {
    margin: 1.5em 0;
  }
}

@media only screen and (min-width: 700px) {
  .header {
    display: flex;
    align-items: center;
    padding: 1em 3em;
  }

  .headerColumn {
    text-align: left;
  }

  .headerRight {
    composes: headerColumn;
    text-align: right;
  }
}

.partnershipLogo {
  max-height: 6em;
  max-width: 14.8125em;
}
