.cancelSubscriptionPage {
  height: 100vh;
  width: 100vw;
}

.cancelSubscriptionContentBox {
  display: block;
  text-align: center;
  margin-top: 5em;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 350px;
  background: white;
  border: 1px solid #29397a;
  padding: 1.5em;
  box-sizing: border-box;
}

.loader {
  border: 5px solid rgb(3, 20, 84);
  border-top: 5px solid #fff;
  border-radius: 50%;
  margin: auto;
  margin-top: 32px;
  width: 31px;
  height: 31px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
